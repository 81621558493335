import React from 'react';
import './PartnerCard.css';
import '../../../../App.css';
import {Col, Row} from "react-bootstrap";
import {isMobile} from 'react-device-detect';
import orientLogo from '../../../../assets/orient-logo.png';
import gtcLogo from '../../../../assets/gtc-logo.png';
import ninjaLogo from '../../../../assets/ninja-express-logo.png';
import mandiriLogo from '../../../../assets/mandiri-logo.png';
import gaiaLogo from '../../../../assets/gaia-cosmo-logo.png';
import galvaLogo from '../../../../assets/galva-logo.png';
import ukridaLogo from '../../../../assets/ukrida-logo.svg';
import wohooLogo from '../../../../assets/wohoo.png'
import ContactUsCard from "../../../CommonComponent/ContactUsCard/ContactUsCard";
import ellipseBg from '../../../../assets/top-ellipse.svg';
import WellnessProgress from "../WellnessProgress/WellnessProgress";
import celebrateGif from '../../../../assets/celebrate-fitness.gif';
import djarumFoundationLogo from '../../../../assets/bakti-pendidikan.png';
import gaiaBandung from '../../../../assets/gaia-bandung.png';
import dunamisLogo from '../../../../assets/dunamis.png';
import colliersLogo from '../../../../assets/lp/colliers-logo.png';
import bostonLogo from '../../../../assets/lp/Boston.png';
import kpi from '../../../../assets/kpi.png';
import amartha from '../../../../assets/logo/amartha-logo.png';
import apkiLogo from '../../../../assets/logo/apki-logo.png';
import bmjLogo from '../../../../assets/logo/bmj-logo.png';
import mindIdLogo from '../../../../assets/logo/mind-id.svg';
import smfLogo from '../../../../assets/logo/smf-logo.png';
import kompasLogo from '../../../../assets/logo/kompas-logo.svg';
import benihBaikLogo from '../../../../assets/logo/benihbaik.png';
import growthCenterLogo from '../../../../assets/logo/growth-center.png';
import kgxLogo from '../../../../assets/logo/kgx.png';
import semaraLogo from '../../../../assets/logo/sembara.png';
import asetkuLogo from '../../../../assets/logo/asetku-logo.png';
import kriParangLogo from '../../../../assets/logo/kri-parang-logo.png';
import pigeonLogo from '../../../../assets/logo/pigeon-logo.png';
import pwcLogo from '../../../../assets/logo/pwc-logo.png';

const PartnerCard = () => {
    const partners = [djarumFoundationLogo, mandiriLogo, kpi, kompasLogo, amartha, dunamisLogo, mindIdLogo, benihBaikLogo, bostonLogo, ninjaLogo, bmjLogo, apkiLogo, kgxLogo, colliersLogo, growthCenterLogo, gtcLogo, orientLogo, galvaLogo, ukridaLogo, smfLogo, gaiaLogo, gaiaBandung, semaraLogo, asetkuLogo, kriParangLogo, pigeonLogo, pwcLogo];
    return (
    <div className='partner-card'>
        <img className='image' src={ellipseBg}/>
        <div className='partner-card-container'>
            <Col md={10} className='auto-margin'>
                <div className='content-title center-label' style={{color: 'white'}}>Loved and Trusted by</div>
                <Row className='pl-md-3 pr-md-3' style={{gap: '3%'}}>
                    {partners.map(value => <Col>
                        <img className='partner-image auto-margin' src={value}/>
                    </Col>)}
                </Row>
                <Row className='card-spacer'>
                    <Col>
                        <Row>
                            <div className='auto-margin d-md-flex flex-md-row'>
                                <Col md={1} sm={12}>
                                    <div className='align-self-center'>
                                        <img className='auto-margin block-disp' style={{height: '70px'}}
                                             src={wohooLogo}/>
                                    </div>
                                </Col>
                                <Col md={11} sm={12}>
                                    <div style={{marginLeft: '12px'}}>
                                        <div className='card-title label-align' style={{color: '#DE1E30'}}>Wohoo!</div>
                                        <div className='content-title label-align' style={{color: 'white'}}>
                                            Let's take a look at our wellness progress
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row className='card-spacer'>
                    <WellnessProgress></WellnessProgress>
                </Row>
            </Col>
            <div style={{marginTop: '200px'}}>
                <ContactUsCard></ContactUsCard>
            </div>
            <div className='card-spacer d-flex flex-row auto-margin justify-content-center celebrate-container'>
                <div className='celebrate-img'>
                    <img style={{height: '90px'}}
                         src={celebrateGif}/>
                </div>
                <div className='celebrate-label align-self-center'>
                    Let's Celebrate Fitness Together
                </div>
            </div>
        </div>
    </div>
)
};

export default PartnerCard;
